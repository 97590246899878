'use strict';

Gri.module({
  name: 'image-animate-wrapper-beta',
  ieVersion: null,
  $el: $('.image-animate-wrapper-beta'),
  container: '.image-animate-wrapper-beta',
  fn: function () {

  }
});
